import { render, staticRenderFns } from "./AppLayout.vue?vue&type=template&id=4d196ab8&"
import script from "./AppLayout.vue?vue&type=script&lang=js&"
export * from "./AppLayout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QList from 'quasar/src/components/item/QList.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';import Ripple from 'quasar/src/directives/Ripple.js';
import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QLayout,QHeader,QToolbar,QItem,QBtn,QDrawer,QList,QItemSection,QAvatar,QPageContainer,QDialog,QCard,QCardSection,QSeparator,QCardActions,QSpinner});qInstall(component, 'directives', {Ripple,ClosePopup});
