<template>
    <q-layout view="hHh lpR fFf" class="overflow-hidden">

        <q-header class="bg-secondary text-primary">
            <q-toolbar class="flex justify-between">
                <q-item clickable to="/" class="z-top">
                    <img alt="Calcola la superficie commerciale con un click" src="images/logo_areaplan.png" style="height: 50px; max-width: 80vw; object-fit: contain"/>
                </q-item>
                <a class="z-top" href="https://help.areaplan.cloud/en/tutorial" target="_blank">
                  <q-btn v-if="$route.name == 'new_project' || $route.name == 'project_edit'" class="q-ml-lg" size="sm" color="grey" text-color="white">{{ $tc('tutorial') }}</q-btn>
                </a>
                <div v-if="remainingTrialDays" class="absolute q-m-auto full-width text-center">
                  <q-btn color="yellow-4" text-color="primary" @click="subscriptionDialogShown = true">{{ $tc('app.subscriptions.remaining_trial_days', remainingTrialDays, { days: remainingTrialDays }) }}</q-btn>
                </div>
                <div class="q-ml-auto">
                    <q-btn color="accent"  dense flat round size="lg" icon="menu" @click="toggleRightMenu" />
                </div>
            </q-toolbar>
        </q-header>

        <q-drawer v-model="right" side="right" overlay behavior="desktop" bordered>
            <div class="flex column full-height">
                <q-list class="col-grow">
                    <q-item clickable @click="showDataDialog" v-ripple>
                        <q-item-section avatar>
                            <q-avatar color="accent" text-color="white" icon="fas fa-user" />
                        </q-item-section>

                        <q-item-section class="text-h6 text-accent text-weight-medium">{{ $t('app.menu.settings') }}</q-item-section>
                    </q-item>
                    <q-item clickable v-ripple :to="{ name: 'projects' }">
                        <q-item-section avatar>
                            <q-avatar square>
                                <img src="images/icons/documents.svg">
                            </q-avatar>
                        </q-item-section>

                        <q-item-section class="text-h6 text-accent text-weight-medium">{{ $t('app.menu.saved_documents') }}</q-item-section>
                    </q-item>
                    <q-item clickable v-ripple @click="logout">
                        <q-item-section avatar>
                            <q-avatar square>
                                <img src="images/icons/logout.svg">
                            </q-avatar>
                        </q-item-section>

                        <q-item-section class="text-h6 text-accent text-weight-medium">{{ $t('app.menu.logout') }}</q-item-section>
                    </q-item>
                </q-list>
            </div>
        </q-drawer>

        <q-page-container style="height: 100vh; padding-top: 66px">
            <router-view :key="$route.fullPath" />
        </q-page-container>

        <q-dialog v-model="subscriptionDialogShown">
            <q-card>
                <q-card-section>
                    <div class="text-h6 text-primary text-center">{{ $t('app.subscriptions.title') }}</div>
                </q-card-section>
                <q-separator />
                <q-card-section class="q-pt-none">
                    <div class="text-h5 text-primary text-center text-weight-medium q-my-md">{{ $t('app.subscriptions.name') }}</div>
                    <div class="text-h4 text-primary text-center text-weight-bold">
                      <template v-if="!loading">{{ $t('app.subscriptions.per_year', { price: `${price}€` }) }}
                        <small>({{ $t('app.subscriptions.vat_included') }})</small>
                      </template>
                      <i v-else class="fa fa-spinner fa-spin"></i>
                    </div>
                    <div class="text-primary text-center q-my-md" v-html="$t('app.subscriptions.accept_terms')"></div>
                </q-card-section>

                <q-card-actions align="center">
                  <q-btn flat :label="$t('generals.cancel')" color="primary" v-close-popup />
                  <q-btn v-if="price" :loading="processing" :label="$t('app.subscriptions.purchase')" color="primary" text-color="white" @click="showDataDialog('subscribe')">
                    <template slot="loading">
                      <q-spinner class="q-mr-md"></q-spinner> {{ $t('In attesa di conferma') }}
                    </template>
                  </q-btn>
                </q-card-actions>
            </q-card>
        </q-dialog>

        <invoice-data-dialog v-if="dataDialogShown" ref="dataDialog" :subscribing="subscribing" :on-hide="invoiceDataDialogHidden" :successCallback="subscribe"></invoice-data-dialog>

    </q-layout>
</template>

<script>

import User from 'src/store/models/User'
// import Company from 'src/store/models/Company'
import moment from 'moment'
import InvoiceDataDialog from 'src/components/InvoiceDataDialog'

export default {
  name: 'AppLayout',

  components: {
    InvoiceDataDialog
  },

  data () {
    return {
      price: 0,
      processing: false,
      loading: false,
      subscriptionDialogShown: false,
      dataDialogShown: false,
      right: false
    }
  },
  watch: {
    'me.company.subscribed': function (val, oldVal) {
      if (!oldVal && val) {
        // subscribed, clear interval and close modal
        clearInterval(this.checkSubscriptionInterval)
        this.subscriptionDialogShown = false
        this.$refs.dataDialog.hide()
      }
    }
  },
  computed: {
    me () {
      return User.me()
    },
    remainingTrialDays () {
      if (this.me && this.me.company && !this.me.company.subscribed) {
        if (this.me.company.trial_ends_at) {
          const remainingDays = moment(this.me.company.trial_ends_at).diff(moment(), 'days')
          return remainingDays >= 0 ? remainingDays + 1 : 0
        }
      }
      return 0
    }
  },
  methods: {
    toggleRightMenu () {
      this.right = !this.right
    },
    fetchPlan () {
      this.loading = true
      this.$axios.get('/api/plan')
        .then((response) => {
          this.price = response.data.total / 100
        })
        .finally(() => {
          this.loading = false
        })
    },
    subscribe () {
      this.$q.loading.show()
      this.me.company.$getCheckoutSession().then(data => {
        window.open(data.url, '_blank')
      })
        .catch(() => {
          this.$q.notify({
            color: 'warning',
            textColor: 'white',
            icon: 'warning',
            message: 'Si è verificato un errore, riprova',
            position: 'top'
          })
        })
        .finally(() => {
          this.$q.loading.hide()
        })
    },
    subscribed () {
      this.$q.notify({
        color: 'positive',
        textColor: 'white',
        icon: 'check',
        message: this.$t('app.subscriptions.purchase_success'),
        position: 'top'
      })
      this.processing = true
      this.checkSubscriptionInterval =
        setInterval(function () {
          User.$getMe()
        }, 5000)
    },
    showDataDialog (mode) {
      this.subscribing = mode === 'subscribe'
      if (this.me.company.dataCompleted && this.subscribing) {
        this.subscribe()
      } else {
        this.dataDialogShown = true
      }
    },
    invoiceDataDialogHidden () {
      this.dataDialogShown = false
    },
    logout () {
      User.$logout()
      this.$store.dispatch('entities/deleteAll')
      this.$router.push({ path: '/auth/login' })
    },
    async fetchData (resolve, reject) {
      await User.$getMe().then(res => {
        resolve()
      }).catch((e) => {
        return reject(e)
      })
    }
  },

  serverPrefetch ({ store, currentRoute, previousRoute, redirect, ssrContext, urlPath, publicPath }) {
    return new Promise((resolve, reject) => {
      this.fetchData(resolve, reject)
    })
  },

  beforeMount () {
    this.fetchPlan()
  },

  async mounted () {
    if (!this.me) {
      this.fetchData(() => {})
    }

    await this.$stripe()

    this.stripeClient = window.Stripe(process.env.STRIPE_KEY)
  }
}
</script>
